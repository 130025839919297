import React, { useState, Fragment, useEffect, Component, useRef } from 'react';
import { Dialog, Transition, Listbox, Combobox } from '@headlessui/react';
import { CheckIcon, SelectorIcon, ChevronUpDownIcon, FastForwardIcon } from '@heroicons/react/solid';
import CustomButton from '../customComponents/buttons'
import ArtistSearchField, { ProfileSearchField } from '../customComponents/lookup'
import _, { TranslatedIsSoldSwitch } from '../customComponents/toggle'
import { useForm } from 'react-hook-form';
import { faAslInterpreting, faL } from '@fortawesome/free-solid-svg-icons';
import isEqual from 'lodash/isEqual';
import { useVendor } from '../../providers/useVendor';
import { useTranslation } from 'react-i18next'

const pricingModels = [
    { 
      id: 1, 
      name: 'Single', 
      description: 'This model offers the artwork as a unique piece, sold to a single buyer at a fixed total price.' 
    },
    { 
      id: 2, 
      name: 'Limited', 
      description: "Under this model, a limited number of artwork copies are available for purchase, each sold at a set price."
    },
    { 
      id: 3, 
      name: 'Unlimited', 
      description: "This model allows for an unlimited number of artwork copies to be sold, each at a consistent price."
    }
]

const currencies = [
    {"cc":"GBP","symbol":"\u00a3","name":"British pound"},
    {"cc":"USD","symbol":"$","name":"United States dollar"},
    {"cc":"EUR","symbol":"\u20ac","name":"European Euro"},
    {"cc":"CNY","symbol":"\u00a5","name":"Chinese/Yuan renminbi"},
    {"cc":"AUD","symbol":"$","name":"Australian dollar"},
    {"cc":"AED","symbol":"\u062f.\u0625;","name":"UAE dirham"},
    {"cc":"AFN","symbol":"Afs","name":"Afghan afghani"},
    {"cc":"ALL","symbol":"L","name":"Albanian lek"},
    {"cc":"AMD","symbol":"AMD","name":"Armenian dram"},
    {"cc":"ANG","symbol":"NA\u0192","name":"Netherlands Antillean gulden"},
    {"cc":"AOA","symbol":"Kz","name":"Angolan kwanza"},
    {"cc":"ARS","symbol":"$","name":"Argentine peso"},
    {"cc":"AWG","symbol":"\u0192","name":"Aruban florin"},
    {"cc":"AZN","symbol":"AZN","name":"Azerbaijani manat"},
    {"cc":"BAM","symbol":"KM","name":"Bosnia and Herzegovina konvertibilna marka"},
    {"cc":"BBD","symbol":"Bds$","name":"Barbadian dollar"},
    {"cc":"BDT","symbol":"\u09f3","name":"Bangladeshi taka"},
    {"cc":"BGN","symbol":"BGN","name":"Bulgarian lev"},
    {"cc":"BHD","symbol":".\u062f.\u0628","name":"Bahraini dinar"},
    {"cc":"BIF","symbol":"FBu","name":"Burundi franc"},
    {"cc":"BMD","symbol":"BD$","name":"Bermudian dollar"},
    {"cc":"BND","symbol":"B$","name":"Brunei dollar"},
    {"cc":"BOB","symbol":"Bs.","name":"Bolivian boliviano"},
    {"cc":"BRL","symbol":"R$","name":"Brazilian real"},
    {"cc":"BSD","symbol":"B$","name":"Bahamian dollar"},
    {"cc":"BTN","symbol":"Nu.","name":"Bhutanese ngultrum"},
    {"cc":"BWP","symbol":"P","name":"Botswana pula"},
    {"cc":"BYR","symbol":"Br","name":"Belarusian ruble"},
    {"cc":"BZD","symbol":"BZ$","name":"Belize dollar"},
    {"cc":"CAD","symbol":"$","name":"Canadian dollar"},
    {"cc":"CDF","symbol":"F","name":"Congolese franc"},
    {"cc":"CHF","symbol":"Fr.","name":"Swiss franc"},
    {"cc":"CLP","symbol":"$","name":"Chilean peso"},
    {"cc":"COP","symbol":"Col$","name":"Colombian peso"},
    {"cc":"CRC","symbol":"\u20a1","name":"Costa Rican colon"},
    {"cc":"CUC","symbol":"$","name":"Cuban peso"},
    {"cc":"CVE","symbol":"Esc","name":"Cape Verdean escudo"},
    {"cc":"CZK","symbol":"K\u010d","name":"Czech koruna"},
    {"cc":"DJF","symbol":"Fdj","name":"Djiboutian franc"},
    {"cc":"DKK","symbol":"Kr","name":"Danish krone"},
    {"cc":"DOP","symbol":"RD$","name":"Dominican peso"},
    {"cc":"DZD","symbol":"\u062f.\u062c","name":"Algerian dinar"},
    {"cc":"EEK","symbol":"KR","name":"Estonian kroon"},
    {"cc":"EGP","symbol":"\u00a3","name":"Egyptian pound"},
    {"cc":"ERN","symbol":"Nfa","name":"Eritrean nakfa"},
    {"cc":"ETB","symbol":"Br","name":"Ethiopian birr"},
    {"cc":"FJD","symbol":"FJ$","name":"Fijian dollar"},
    {"cc":"FKP","symbol":"\u00a3","name":"Falkland Islands pound"},
    {"cc":"GEL","symbol":"GEL","name":"Georgian lari"},
    {"cc":"GHS","symbol":"GH\u20b5","name":"Ghanaian cedi"},
    {"cc":"GIP","symbol":"\u00a3","name":"Gibraltar pound"},
    {"cc":"GMD","symbol":"D","name":"Gambian dalasi"},
    {"cc":"GNF","symbol":"FG","name":"Guinean franc"},
    {"cc":"GQE","symbol":"CFA","name":"Central African CFA franc"},
    {"cc":"GTQ","symbol":"Q","name":"Guatemalan quetzal"},
    {"cc":"GYD","symbol":"GY$","name":"Guyanese dollar"},
    {"cc":"HKD","symbol":"HK$","name":"Hong Kong dollar"},
    {"cc":"HNL","symbol":"L","name":"Honduran lempira"},
    {"cc":"HRK","symbol":"kn","name":"Croatian kuna"},
    {"cc":"HTG","symbol":"G","name":"Haitian gourde"},
    {"cc":"HUF","symbol":"Ft","name":"Hungarian forint"},
    {"cc":"IDR","symbol":"Rp","name":"Indonesian rupiah"},
    {"cc":"ILS","symbol":"\u20aa","name":"Israeli new sheqel"},
    {"cc":"INR","symbol":"\u20B9","name":"Indian rupee"},
    {"cc":"IQD","symbol":"\u062f.\u0639","name":"Iraqi dinar"},
    {"cc":"IRR","symbol":"IRR","name":"Iranian rial"},
    {"cc":"ISK","symbol":"kr","name":"Icelandic kr\u00f3na"},
    {"cc":"JMD","symbol":"J$","name":"Jamaican dollar"},
    {"cc":"JOD","symbol":"JOD","name":"Jordanian dinar"},
    {"cc":"JPY","symbol":"\u00a5","name":"Japanese yen"},
    {"cc":"KES","symbol":"KSh","name":"Kenyan shilling"},
    {"cc":"KGS","symbol":"\u0441\u043e\u043c","name":"Kyrgyzstani som"},
    {"cc":"KHR","symbol":"\u17db","name":"Cambodian riel"},
    {"cc":"KMF","symbol":"KMF","name":"Comorian franc"},
    {"cc":"KPW","symbol":"W","name":"North Korean won"},
    {"cc":"KRW","symbol":"W","name":"South Korean won"},
    {"cc":"KWD","symbol":"KWD","name":"Kuwaiti dinar"},
    {"cc":"KYD","symbol":"KY$","name":"Cayman Islands dollar"},
    {"cc":"KZT","symbol":"T","name":"Kazakhstani tenge"},
    {"cc":"LAK","symbol":"KN","name":"Lao kip"},
    {"cc":"LBP","symbol":"\u00a3","name":"Lebanese lira"},
    {"cc":"LKR","symbol":"Rs","name":"Sri Lankan rupee"},
    {"cc":"LRD","symbol":"L$","name":"Liberian dollar"},
    {"cc":"LSL","symbol":"M","name":"Lesotho loti"},
    {"cc":"LTL","symbol":"Lt","name":"Lithuanian litas"},
    {"cc":"LVL","symbol":"Ls","name":"Latvian lats"},
    {"cc":"LYD","symbol":"LD","name":"Libyan dinar"},
    {"cc":"MAD","symbol":"MAD","name":"Moroccan dirham"},
    {"cc":"MDL","symbol":"MDL","name":"Moldovan leu"},
    {"cc":"MGA","symbol":"FMG","name":"Malagasy ariary"},
    {"cc":"MKD","symbol":"MKD","name":"Macedonian denar"},
    {"cc":"MMK","symbol":"K","name":"Myanma kyat"},
    {"cc":"MNT","symbol":"\u20ae","name":"Mongolian tugrik"},
    {"cc":"MOP","symbol":"P","name":"Macanese pataca"},
    {"cc":"MRO","symbol":"UM","name":"Mauritanian ouguiya"},
    {"cc":"MUR","symbol":"Rs","name":"Mauritian rupee"},
    {"cc":"MVR","symbol":"Rf","name":"Maldivian rufiyaa"},
    {"cc":"MWK","symbol":"MK","name":"Malawian kwacha"},
    {"cc":"MXN","symbol":"$","name":"Mexican peso"},
    {"cc":"MYR","symbol":"RM","name":"Malaysian ringgit"},
    {"cc":"MZM","symbol":"MTn","name":"Mozambican metical"},
    {"cc":"NAD","symbol":"N$","name":"Namibian dollar"},
    {"cc":"NGN","symbol":"\u20a6","name":"Nigerian naira"},
    {"cc":"NIO","symbol":"C$","name":"Nicaraguan c\u00f3rdoba"},
    {"cc":"NOK","symbol":"kr","name":"Norwegian krone"},
    {"cc":"NPR","symbol":"NRs","name":"Nepalese rupee"},
    {"cc":"NZD","symbol":"NZ$","name":"New Zealand dollar"},
    {"cc":"OMR","symbol":"OMR","name":"Omani rial"},
    {"cc":"PAB","symbol":"B./","name":"Panamanian balboa"},
    {"cc":"PEN","symbol":"S/.","name":"Peruvian nuevo sol"},
    {"cc":"PGK","symbol":"K","name":"Papua New Guinean kina"},
    {"cc":"PHP","symbol":"\u20b1","name":"Philippine peso"},
    {"cc":"PKR","symbol":"Rs.","name":"Pakistani rupee"},
    {"cc":"PLN","symbol":"z\u0142","name":"Polish zloty"},
    {"cc":"PYG","symbol":"\u20b2","name":"Paraguayan guarani"},
    {"cc":"QAR","symbol":"QR","name":"Qatari riyal"},
    {"cc":"RON","symbol":"L","name":"Romanian leu"},
    {"cc":"RSD","symbol":"din.","name":"Serbian dinar"},
    {"cc":"RUB","symbol":"R","name":"Russian ruble"},
    {"cc":"SAR","symbol":"SR","name":"Saudi riyal"},
    {"cc":"SBD","symbol":"SI$","name":"Solomon Islands dollar"},
    {"cc":"SCR","symbol":"SR","name":"Seychellois rupee"},
    {"cc":"SDG","symbol":"SDG","name":"Sudanese pound"},
    {"cc":"SEK","symbol":"kr","name":"Swedish krona"},
    {"cc":"SGD","symbol":"S$","name":"Singapore dollar"},
    {"cc":"SHP","symbol":"\u00a3","name":"Saint Helena pound"},
    {"cc":"SLL","symbol":"Le","name":"Sierra Leonean leone"},
    {"cc":"SOS","symbol":"Sh.","name":"Somali shilling"},
    {"cc":"SRD","symbol":"$","name":"Surinamese dollar"},
    {"cc":"SYP","symbol":"LS","name":"Syrian pound"},
    {"cc":"SZL","symbol":"E","name":"Swazi lilangeni"},
    {"cc":"THB","symbol":"\u0e3f","name":"Thai baht"},
    {"cc":"TJS","symbol":"TJS","name":"Tajikistani somoni"},
    {"cc":"TMT","symbol":"m","name":"Turkmen manat"},
    {"cc":"TND","symbol":"DT","name":"Tunisian dinar"},
    {"cc":"TRY","symbol":"TRY","name":"Turkish new lira"},
    {"cc":"TTD","symbol":"TT$","name":"Trinidad and Tobago dollar"},
    {"cc":"TWD","symbol":"NT$","name":"New Taiwan dollar"},
    {"cc":"TZS","symbol":"TZS","name":"Tanzanian shilling"},
    {"cc":"UAH","symbol":"UAH","name":"Ukrainian hryvnia"},
    {"cc":"UGX","symbol":"USh","name":"Ugandan shilling"},
    {"cc":"UYU","symbol":"$U","name":"Uruguayan peso"},
    {"cc":"UZS","symbol":"UZS","name":"Uzbekistani som"},
    {"cc":"VEB","symbol":"Bs","name":"Venezuelan bolivar"},
    {"cc":"VND","symbol":"\u20ab","name":"Vietnamese dong"},
    {"cc":"VUV","symbol":"VT","name":"Vanuatu vatu"},
    {"cc":"WST","symbol":"WS$","name":"Samoan tala"},
    {"cc":"XAF","symbol":"CFA","name":"Central African CFA franc"},
    {"cc":"XCD","symbol":"EC$","name":"East Caribbean dollar"},
    {"cc":"XDR","symbol":"SDR","name":"Special Drawing Rights"},
    {"cc":"XOF","symbol":"CFA","name":"West African CFA franc"},
    {"cc":"XPF","symbol":"F","name":"CFP franc"},
    {"cc":"YER","symbol":"YER","name":"Yemeni rial"},
    {"cc":"ZAR","symbol":"R","name":"South African rand"},
    {"cc":"ZMK","symbol":"ZK","name":"Zambian kwacha"},
    {"cc":"ZWR","symbol":"Z$","name":"Zimbabwean dollar"}
];

const func = ()=> {}

const formatCurrency = (value) => {
    const numericValue = parseFloat(value.replace(/[£,]/g, ''));
    if (isNaN(numericValue)) {
        return "£0";
    }
    return "£" + numericValue.toLocaleString('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
};

const handleCurrencyInput = (event) => {
    const { name, value } = event.target;
    const formattedValue = formatCurrency(value);
    // setValue(name, formattedValue, { shouldValidate: true });
};

const findCurrency = (currencyCode) => {
    return currencies.find(currency => currency.cc === currencyCode);
}

const findPricingModels = (name) => {
    return pricingModels.find(model => model.name === name);
}

export function CurrencyInputCombo({ onChange = func, disabled = false, label = '', defaultValue = {'amount': 0, 'currency': 'USD'}, labelDesc='' }) {

    const [isFocused, setIsFocused] = useState(false); 
    const [selectedCurrency, setSelectedCurrency] = useState(findCurrency(defaultValue.currency));    
    const [amount, setAmount] = useState(defaultValue.amount);
    const [query, setQuery] = useState('')
    const currencyInputRef = useRef(null);
    const prevCurrency = useRef(selectedCurrency.cc);
    
    useEffect(()=>{
        if(isFocused || prevCurrency !== selectedCurrency.cc){
            onChange({
                'amount': formattedAmount(amount),
                'currency': selectedCurrency.cc
            })
            prevCurrency.current = selectedCurrency.cc;
        }
    }, [selectedCurrency, amount])

    useEffect(()=>{
        if(!isFocused){
            setSelectedCurrency(findCurrency(defaultValue.currency));
            setAmount(defaultValue.amount);
        }
    }, [defaultValue])

    const formattedAmount = (amount) => String(amount).replace(/,/g, '')
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false); 

    const handleAmountInput = (e) =>{
        
        const value = e.target.value;
        
        const formatAmount = (value) => {

            let numericValue = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
            
            const endsWithDot = numericValue.endsWith('.');
            
            numericValue = numericValue.replace(/(\.\d{2})\d+/g, '$1');
            
            let [integerPart, decimalPart] = numericValue.split('.');

            integerPart = integerPart.replace(/^0+/, '') || '0';

            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            return decimalPart ? `${integerPart}.${decimalPart}` : endsWithDot ? `${integerPart}.` : integerPart;

        };
        
        const formattedAmount = formatAmount(value);
        if (formattedAmount !== amount) {
            setAmount(formattedAmount);
        }
    }

    const onSelectCurrency = () => {
        setTimeout(() => {
            if (currencyInputRef.current) {
                currencyInputRef.current.focus();
            }
        }, 10);
    };
  
    const queryNormalized = query.toLowerCase().replace(/\s+/g, '');

    const filteredCurrencies = query === '' ? currencies : currencies.filter(({name, cc, symbol}) => {        
        const testString = (str) => str.toLowerCase().replace(/\s+/g, '').includes(queryNormalized);
        return [name, cc, symbol].some(testString);
    })


    return (
    <>
        <div className='flex justify-between'>
            <label htmlFor="pricePerPrint" className="block text-sm font-medium text-white text-left">{label}</label>
            {labelDesc && (
            <div className='tooltip'>
                <svg  style={{width: '10px', 'height': '10px'}} width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.7 4.5H3.3V2.7H2.7V4.5ZM3 2.1C3.085 2.1 3.1563 2.0712 3.2139 2.0136C3.2715 1.956 3.3002 1.8848 3.3 1.8C3.3 1.715 3.2712 1.6438 3.2136 1.5864C3.156 1.529 3.0848 1.5002 3 1.5C2.915 1.5 2.8438 1.5288 2.7864 1.5864C2.729 1.644 2.7002 1.7152 2.7 1.8C2.7 1.885 2.7288 1.9563 2.7864 2.0139C2.844 2.0715 2.9152 2.1002 3 2.1ZM3 6C2.585 6 2.195 5.9212 1.83 5.7636C1.465 5.606 1.1475 5.3923 0.8775 5.1225C0.6075 4.8525 0.3938 4.535 0.2364 4.17C0.079 3.805 0.0002 3.415 0 3C0 2.585 0.0788 2.195 0.2364 1.83C0.394 1.465 0.6077 1.1475 0.8775 0.8775C1.1475 0.6075 1.465 0.3938 1.83 0.2364C2.195 0.079 2.585 0.0002 3 0C3.415 0 3.805 0.0788 4.17 0.2364C4.535 0.394 4.8525 0.6077 5.1225 0.8775C5.3925 1.1475 5.6063 1.465 5.7639 1.83C5.9215 2.195 6.0002 2.585 6 3C6 3.415 5.9212 3.805 5.7636 4.17C5.606 4.535 5.3923 4.8525 5.1225 5.1225C4.8525 5.3925 4.535 5.6063 4.17 5.7639C3.805 5.9215 3.415 6.0002 3 6ZM3 5.4C3.67 5.4 4.2375 5.1675 4.7025 4.7025C5.1675 4.2375 5.4 3.67 5.4 3C5.4 2.33 5.1675 1.7625 4.7025 1.2975C4.2375 0.8325 3.67 0.6 3 0.6C2.33 0.6 1.7625 0.8325 1.2975 1.2975C0.8325 1.7625 0.6 2.33 0.6 3C0.6 3.67 0.8325 4.2375 1.2975 4.7025C1.7625 5.1675 2.33 5.4 3 5.4Z" fill="#5A5956"/>
                </svg>
                <span class="tooltiptext" style={{paddingRight: '35px'}}>
                    {labelDesc}
                </span>
            </div>
            )}
        </div>
        <div className="flex items-center">
        <Listbox value={selectedCurrency} onChange={setSelectedCurrency} disabled={disabled}>
          {({ open }) => (
            <>
                <Listbox.Label className="sr-only">Currency</Listbox.Label>
                <div className="relative">
                    <Listbox.Button className={`relative h-[60px] min-w-[110px] text-center font-roboto mt-1 text-white rounded-md rounded-r-none shadow-sm pl-5 pr-5 py-2 text-left cursor-default focus:outline-none`}
                    onClick={onSelectCurrency}
                    style={{
                        ...(isFocused ? {
                            backgroundColor:'#202020b5',
                            boxShadow: '-1px 0 0 0 #F2C14E, 0 1px 0 0 #F2C14E, 0 -1px 0 0 #F2C14E',
                            boxSizing: 'border-box',
                            borderImage: 'linear-gradient(transparent 10%, #ffffff45 10%, #ffffff45 90%, transparent 90%) 1 / 0 2px 0 0 / 0 stretch',
                        } : {
                            backgroundColor: '#3c3c3cb9',
                            borderImage: 'linear-gradient(transparent 10%, #afafaf29 10%, #afafaf29 90%, transparent 90%) 1 / 0 2px 0 0 / 0 stretch',
                        })
                    }}> 
                    {selectedCurrency.symbol} ({selectedCurrency.cc})
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                    </span>
                    </Listbox.Button>
                    <Transition as={Fragment} leave="transition ease-in duration-100"leaveFrom="opacity-100"leaveTo="opacity-0">
                        <Listbox.Options className="absolute z-10 w-[200px] mt-1 bg-secondary shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5  focus:outline-none sm:text-sm">          
                        <div className='flex justify-center'>
                            
                            <div className='relative inline-block'>
                                
                                <input 
                                ref={currencyInputRef}
                                className='w-[185px] my-2' 
                                onChange={(e) => {setQuery(e.currentTarget.value)}} 
                                value={query}
                                />

                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6" style={{
                                        position: 'absolute',
                                        right: '10px', // Adjust based on padding and desired position
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        pointerEvents: 'none', // Ensures the icon doesn't interfere with input
                                    }}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                                </svg>
                            </div>

                        </div>
                        
                        {filteredCurrencies.length == 0 ? (
                            <div className='text-white py-5 px-2'>No currency available.</div>
                        ): null}
                        <div className='overflow-auto max-h-[190px] bg-secondary shadow-lg '>                    
                        {filteredCurrencies.map((currency) => (
                        <Listbox.Option key={currency.cc} 
                        className={({ active }) => `relative cursor-default select-none py-2 p-1 pr-4 ${ active ? 'bg-indigo-100' : 'text-white'}`} 
                        value={currency}>
                            {({ selected, active }) => (
                            <>
                                <span className={`block px-2 truncate font-normal font-roboto ${selected ? 'font-medium text-orange-300' : null}`}>
                                {currency.symbol} ({currency.cc})
                                </span>
                            </>
                            )}
                        </Listbox.Option>
                        ))}
                    </div>
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        <input
          type="text"
          value={amount}
          onChange={handleAmountInput}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder="Amount"
          className="combo h-[60px] rounded-l-none mt-1 block w-full px-3 py-2 font-roboto"
          disabled={disabled}
          style={{
            borderRadius: '0px 8px 8px 0px', 
        }}/>
        </div>
    </>   
    );
}
  

export default function ModalComponent({ onChange = func, defaultValue = {}, onReset = null }) {

    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState(defaultValue)
    const [selectedPricingModel, setSelectedPricingModel] = useState(findPricingModels(defaultValue?.pricing_type || 'Single'));
    const [isArtworkForSale, setIsArtworkForSale] = useState('nonSale');
    const [isValid, setIsValid] = useState(false);
    const [owner, setOwner] = useState(null);

    const { vendor } = useVendor()
    const { t } = useTranslation()


    const [totalPrice, setTotalPrice] = useState({
        'amount': 0,
        'currency': 'USD'
    })

    const [pricePerPrint, setPricePerPrint] = useState({
        'amount': 0,
        'currency': 'USD'
    })
    
    useEffect(()=>{
        if(selectedPricingModel.name !== 'Single'){
            setTotalPrice({
                'amount': totalPrice.amount,
                'currency': pricePerPrint.currency
            })
        }
    }, [pricePerPrint])

    useEffect(()=>{
        if(!(selectedPricingModel.name === 'Limited' || selectedPricingModel.name === 'Unlimited')){
            setPricePerPrint({
                'amount': pricePerPrint.amount,
                'currency': totalPrice.currency
            })
        }
    }, [totalPrice])

    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm({});

    useEffect(()=>{
        const pricingModel = findPricingModels(defaultValue?.pricing_type || 'Single');
        setSelectedPricingModel(pricingModel);

        if (defaultValue?.unit_price) {
            setPricePerPrint(defaultValue.unit_price);
        } else setPricePerPrint({'amount': 0, 'currency': 'USD'});

        if (defaultValue?.single_price) {
            setTotalPrice(defaultValue.single_price);
        } else setTotalPrice({'amount': 0, 'currency': 'USD'});

        if (defaultValue?.quantity) {
            setValue('quantity', defaultValue?.quantity);
        } else setValue('quantity', 1);

    }, [defaultValue])


    const [isQuantityDisabled, setIsQuantityDisabled] = useState(false);
    const [isPricePerPrintDisabled, setIsPricePerPrintDisabled] = useState(false);
    const [isTotalPriceDisabled, setIsTotalPriceDisabled] = useState(false);
    const [isSelectProfileDisabled, setIsSelectProfileDisabled] = useState(true);
    
    const formSubmittedRef = useRef(false);
    const watchedQuantity = watch('quantity');


    useEffect(()=>{
        if (selectedPricingModel?.name === 'Single') {               
            setIsValid(true)
        } else if (selectedPricingModel?.name === 'Limited') {
            if(watchedQuantity > 0){
                setIsValid(true)
            } else {
                setIsValid(false)
            };
        } else if (selectedPricingModel?.name === 'Unlimited') {
            if(pricePerPrint.amount > 0){
                setIsValid(true)
            } else {
                setIsValid(false)
            }
        }
    
        if (isArtworkForSale == 'sale' && owner) {
            setIsValid(true)
        } else if (isArtworkForSale == 'sale' && !owner)(
            setIsValid(false)
        )
  
    })

    useEffect(() => {
        const handlePricingModelChanges = () => {
            if (selectedPricingModel?.name === 'Single') {
                setValue('quantity', 1)
                setPricePerPrint({
                    'amount': pricePerPrint.amount,
                    'currency': pricePerPrint.currency
                })
                setTotalPrice({
                    'amount': pricePerPrint.amount,
                    'currency': pricePerPrint.currency
                })
                setIsQuantityDisabled(true);
                setIsPricePerPrintDisabled(false);
                setIsTotalPriceDisabled(false);
            } else if (selectedPricingModel?.name === 'Limited') {
                setTotalPrice({
                    'amount': pricePerPrint.amount,
                    'currency': pricePerPrint.currency
                })
                setPricePerPrint({
                    'amount': pricePerPrint.amount,
                    'currency': pricePerPrint.currency
                })
                setIsQuantityDisabled(false);
                setIsPricePerPrintDisabled(false);
                setIsTotalPriceDisabled(true);
            } else if (selectedPricingModel?.name === 'Unlimited') {
                setValue('quantity', '∞');
                setPricePerPrint({
                    'amount': pricePerPrint.amount,
                    'currency': pricePerPrint.currency
                })
                setTotalPrice({
                    'amount': pricePerPrint.amount,
                    'currency': pricePerPrint.currency
                })
                setIsQuantityDisabled(true);
                setIsPricePerPrintDisabled(false);
                setIsTotalPriceDisabled(true);
            }
        };
    
        const handleSaleStatusChanges = () => {
            if (isArtworkForSale === 'sale') {
                setPricePerPrint({
                    'amount': pricePerPrint.amount,
                    'currency': pricePerPrint.currency
                })
                setTotalPrice({
                    'amount': pricePerPrint.amount,
                    'currency': pricePerPrint.currency
                })

                setIsQuantityDisabled(true);
                setIsPricePerPrintDisabled(true);
                setIsTotalPriceDisabled(true);
            } else if (isArtworkForSale === 'nonsale') {
                handlePricingModelChanges();
            }
        };
    
        if (isArtworkForSale == 'sale') {
            handleSaleStatusChanges();
            setIsSelectProfileDisabled(false)

        } else {
            handlePricingModelChanges();
            setIsSelectProfileDisabled(true)
        }
    }, [selectedPricingModel, isArtworkForSale]);

    useEffect(() => {        
        if(formSubmittedRef.current==false || !formSubmittedRef.isCreated){
            setFormData(defaultValue);
            setSelectedPricingModel(findPricingModels(defaultValue?.pricing_type || 'Single'))
            setValue('quantity', defaultValue?.quantity || 1);
            setIsArtworkForSale(defaultValue?.metadata?.is_sold ? 'sale' : 'nonSale')
        } else {
            formSubmittedRef.current = false;
            formSubmittedRef.isCreated = false;
        }
    }, [defaultValue]); 

    const onSubmit = () => {

        const isCreated = Object.keys(formData).length === 0

        let payload = {
            pricing_type: selectedPricingModel.name,
            product_type: selectedPricingModel.name.toLowerCase(),
            quantity: !isNaN(watchedQuantity) ? watchedQuantity : 1,
            price: pricePerPrint.amount,
            unit_price: pricePerPrint,
            dimensions: {}
        };
    
        if (isArtworkForSale == 'sale') {
            payload = {
                ...payload,
                metadata: { 
                    is_sold: isArtworkForSale === 'sale',
                    owner:  owner
                }
            };
        }

        formSubmittedRef.current = true;
        formSubmittedRef.isCreated = isCreated;

        onChange(payload)
        setFormData(payload)
        setIsOpen(false)      
    };

    const onRemove = () => {
        setSelectedPricingModel(findPricingModels('Single'))
        setValue('quantity', 1);
        setIsArtworkForSale('nonSale')

        onChange({})
        setFormData({})
    }

    const openModal = () => {
        setIsOpen(true)
    }

    const closeModal = () => {
        setIsOpen(false)
    }

    const priceIsZeroMessage = 'Warning the artwork price you are setting is 0'

    return (
    <>
        {Object.keys(formData).length === 0 ? (
            <div className='mt-2 mb-2'> 
                <CustomButton 
                    className='primaryButton' 
                    onClick={() => setIsOpen(true)}
                    singleUse={true}
                    textPos="left"
                    >
                    <span>Artwork Monetisation</span>
                </CustomButton>                
            </div>
        ) : (
            <>
                <h5 className='text-left text-white text-sm font-medium mb-1'>Artwork Monetisation</h5>
                <div onClick={openModal} className='flex min-h-[80px] justify-between mb-1 bg-secondary rounded-md outline outline-1 outline-shaded-grey-dark p-2'>
                    <div className='text-shaded-grey-light/80 text-left'>

                        {formData?.metadata?.is_sold ? (
                            <h5 className='text-sm font-medium text-shaded-grey-light'>Sold</h5>
                        ) : (
                            <h5 className='text-sm font-medium text-shaded-grey-light'>{formData.pricing_type}</h5>
                        )}
            
                        {isArtworkForSale === 'sale' ? (
                            <>
                                <h6 className='text-sm'>Artwork sold to {formData?.metadata?.owner?.profile_handle}</h6>
                                <h6 className='text-sm'></h6>
                            </>
                        ) : (
                            <>

                            {formData.metadata == 'Single' ? (
                                <>
                                    <h6 className='text-sm'>Total Price {formData.unit_price.currency} {formData.unit_price.amount}</h6>
                                    <h6 className='text-sm'>Quantity 1</h6>
                                </>
                            ) : (null)}

                            {formData.pricing_type == 'Single' ? (
                                <>
                                    <h6 className='text-sm'>Total Price {formData.unit_price.currency} {formData.unit_price.amount}</h6>
                                    <h6 className='text-sm'>Quantity 1</h6>
                                </>
                            ) : (null)}
                            
                            {formData.pricing_type == 'Limited' ? (
                                <>
                                    <h6 className='text-sm'>Price Per Print (PPP) {formData.unit_price.currency} {formData.unit_price.amount}</h6>
                                    <h6 className='text-sm'>Quantity {formData.quantity}</h6>
                                </>
                            ) : (null)}

                            {formData.pricing_type == 'Unlimited' ? (
                                <>
                                    <h6 className='text-sm'>Price Per Print (PPP) {formData.unit_price.currency} {formData.unit_price.amount}</h6>
                                    <h6 className='text-sm'>Unlimited</h6>                        
                                </>
                            ) : (null)}  

                            </>
                        )}                    
                        
                    </div>
                    <div className='my-auto'>
                        <a className='text-sm bg-transparent font-medium cursor-pointer' onClick={(e) => { e.stopPropagation(); onRemove(); }}> Remove </a>
                    </div>
                </div>
            </>
        )}

        <Dialog open={isOpen} onClose={closeModal} className="relative z-50">
        <div className="fixed inset-0 bg-black/60 backdrop-blur-sm" aria-hidden="true" />

        <div className="fixed inset-0 flex items-center justify-center p-4">
            <Dialog.Panel className="w-full max-w-4xl h-auto max-h-[90vh] mx-auto overflow-y-auto overflow-x-hidden rounded-lg bg-primary-tint p-6 shadow-lg w-[700px]">

                <Dialog.Title className="text-lg text-white font-semibold">
                    <div className='mx-5 mt-1'>
                        <h4>{t('Artwork Monetisation')}</h4>
                        <div class="relative flex pt-3 items-center">
                            <div class="flex-grow border-t border-gray-500">
                            {!vendor && (
                                <div className='text-yellow-300'>
                                    Please note that you are not a vendor. To publish on our marketplace please visit our&nbsp;
                                    <a className='bg-transparent p-0 text-yellow-300 underline'  target="_blank" href='https://upload.artera.ae/settings'>Account settings</a>
                                    &nbsp;to register.
                                </div>
                            )}
                            </div>
                        </div>
                    </div>
                </Dialog.Title>


                <Dialog.Description className="mt-2 text-sm text-gray-500 mb-4">
                <form onSubmit={onSubmit} className='w-[100%]'>

                    <div class="grid md:grid-cols-2 sm:grid-cols-1 gap-y-8 mt-3">

                        <div className='custom-field'>
                            <Listbox value={selectedPricingModel} onChange={setSelectedPricingModel} disabled={isArtworkForSale == 'sale'}>
                                {({ open }) => (
                                    <>
                                    <div className='flex justify-between'>
                                        <Listbox.Label className="block text-sm font-medium text-white">{t('Pricing Model')}</Listbox.Label>
                                        <div className='tooltip'>
                                            <svg  style={{width: '10px', 'height': '10px'}} width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.7 4.5H3.3V2.7H2.7V4.5ZM3 2.1C3.085 2.1 3.1563 2.0712 3.2139 2.0136C3.2715 1.956 3.3002 1.8848 3.3 1.8C3.3 1.715 3.2712 1.6438 3.2136 1.5864C3.156 1.529 3.0848 1.5002 3 1.5C2.915 1.5 2.8438 1.5288 2.7864 1.5864C2.729 1.644 2.7002 1.7152 2.7 1.8C2.7 1.885 2.7288 1.9563 2.7864 2.0139C2.844 2.0715 2.9152 2.1002 3 2.1ZM3 6C2.585 6 2.195 5.9212 1.83 5.7636C1.465 5.606 1.1475 5.3923 0.8775 5.1225C0.6075 4.8525 0.3938 4.535 0.2364 4.17C0.079 3.805 0.0002 3.415 0 3C0 2.585 0.0788 2.195 0.2364 1.83C0.394 1.465 0.6077 1.1475 0.8775 0.8775C1.1475 0.6075 1.465 0.3938 1.83 0.2364C2.195 0.079 2.585 0.0002 3 0C3.415 0 3.805 0.0788 4.17 0.2364C4.535 0.394 4.8525 0.6077 5.1225 0.8775C5.3925 1.1475 5.6063 1.465 5.7639 1.83C5.9215 2.195 6.0002 2.585 6 3C6 3.415 5.9212 3.805 5.7636 4.17C5.606 4.535 5.3923 4.8525 5.1225 5.1225C4.8525 5.3925 4.535 5.6063 4.17 5.7639C3.805 5.9215 3.415 6.0002 3 6ZM3 5.4C3.67 5.4 4.2375 5.1675 4.7025 4.7025C5.1675 4.2375 5.4 3.67 5.4 3C5.4 2.33 5.1675 1.7625 4.7025 1.2975C4.2375 0.8325 3.67 0.6 3 0.6C2.33 0.6 1.7625 0.8325 1.2975 1.2975C0.8325 1.7625 0.6 2.33 0.6 3C0.6 3.67 0.8325 4.2375 1.2975 4.7025C1.7625 5.1675 2.33 5.4 3 5.4Z" fill="#5A5956"/>
                                            </svg>
                                            <span class="tooltiptext">
                                            {t('The model you want to set the pricing to when your artwork is sold.')}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="mt-1 relative">
                                        <Listbox.Button className="relative h-[60px] w-full bg-primary  rounded-md pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm">
                                        <span className="block truncate text-white">{t(selectedPricingModel.name)}</span>
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                        </Listbox.Button>

                                        <Transition
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                        >
                                        <Listbox.Options className="absolute z-10 mt-1 w-full bg-secondary  shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                            {pricingModels.map((model) => (
                                                <Listbox.Option
                                                    key={model.id}
                                                    className={({ active }) =>
                                                    `relative cursor-default select-none py-2 pl-5 pr-5 text-gray-900 ${
                                                        active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900'
                                                    }`
                                                    }
                                                    value={model}
                                                >
                                                {({ selected, active }) => (
                                                <>
                                                    <span className={`font-roboto md:text-md font-medium  block ${selected ? 'text-orange-300' : 'text-shaded-grey-light'}`}>
                                                    {t(model.name)}
                                                    </span>
                                                    <span className="font-roboto md:text-md font-normal text-shaded-grey-dark">
                                                    {t(model.descriptio)}
                                                    </span>
                                                </>
                                                )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                        </Transition>
                                    </div>
                                    </>
                                )}
                            </Listbox>
                        </div>
                        
                        {/* <div className='custom-field combo-input'>
                            <CurrencyInputCombo 
                            onChange={setTotalPrice} 
                            disabled={isTotalPriceDisabled}
                            defaultValue={totalPrice}
                            label='Total Price'
                            labelDesc='Price of total item sold'
                            />
                            {totalPrice?.amount == 0 
                            && selectedPricingModel.name === 'Single' 
                            && isArtworkForSale !== 'sale' && (
                                <div className='block md:absolute text-yellow-300 mt-[5px] ml-0'>
                                    {priceIsZeroMessage}
                                </div>
                            )}
                        </div>  */}

                        <div className='custom-field combo-input'>
                            <CurrencyInputCombo 
                            onChange={setPricePerPrint} 
                            disabled={isPricePerPrintDisabled}
                            defaultValue={pricePerPrint}
                            label={t('Total Price')}
                            labelDesc={t('Price of each item sold')}
                            />
                            {pricePerPrint?.amount == 0 && (selectedPricingModel.name === 'Limited' 
                            || selectedPricingModel.name === 'Unlimited') 
                            && isArtworkForSale !== 'sale'  && (
                                <div className='block md:absolute text-yellow-300 mt-[5px] ml-0'>
                                   {t(priceIsZeroMessage)}
                                </div>
                            )}
                        </div>

                        <div className='custom-field'>
                            <div className='flex justify-between'>
                                <label htmlFor="quantity" className="block text-sm font-medium text-white text-left">
                                {t('Quantity')}
                                </label>
                                <div className='tooltip'>
                                    <svg  style={{width: '10px', 'height': '10px'}} width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.7 4.5H3.3V2.7H2.7V4.5ZM3 2.1C3.085 2.1 3.1563 2.0712 3.2139 2.0136C3.2715 1.956 3.3002 1.8848 3.3 1.8C3.3 1.715 3.2712 1.6438 3.2136 1.5864C3.156 1.529 3.0848 1.5002 3 1.5C2.915 1.5 2.8438 1.5288 2.7864 1.5864C2.729 1.644 2.7002 1.7152 2.7 1.8C2.7 1.885 2.7288 1.9563 2.7864 2.0139C2.844 2.0715 2.9152 2.1002 3 2.1ZM3 6C2.585 6 2.195 5.9212 1.83 5.7636C1.465 5.606 1.1475 5.3923 0.8775 5.1225C0.6075 4.8525 0.3938 4.535 0.2364 4.17C0.079 3.805 0.0002 3.415 0 3C0 2.585 0.0788 2.195 0.2364 1.83C0.394 1.465 0.6077 1.1475 0.8775 0.8775C1.1475 0.6075 1.465 0.3938 1.83 0.2364C2.195 0.079 2.585 0.0002 3 0C3.415 0 3.805 0.0788 4.17 0.2364C4.535 0.394 4.8525 0.6077 5.1225 0.8775C5.3925 1.1475 5.6063 1.465 5.7639 1.83C5.9215 2.195 6.0002 2.585 6 3C6 3.415 5.9212 3.805 5.7636 4.17C5.606 4.535 5.3923 4.8525 5.1225 5.1225C4.8525 5.3925 4.535 5.6063 4.17 5.7639C3.805 5.9215 3.415 6.0002 3 6ZM3 5.4C3.67 5.4 4.2375 5.1675 4.7025 4.7025C5.1675 4.2375 5.4 3.67 5.4 3C5.4 2.33 5.1675 1.7625 4.7025 1.2975C4.2375 0.8325 3.67 0.6 3 0.6C2.33 0.6 1.7625 0.8325 1.2975 1.2975C0.8325 1.7625 0.6 2.33 0.6 3C0.6 3.67 0.8325 4.2375 1.2975 4.7025C1.7625 5.1675 2.33 5.4 3 5.4Z" fill="#5A5956"/>
                                    </svg>
                                    <span class="tooltiptext" style={{paddingRight: '35px'}}>
                                        {t('The quantity of artworks sold.')}
                                    </span>
                                </div>
                            </div>
                            <input 
                                {...register('quantity', { 
                                required: "Quantity is required", 
                                valueAsNumber: true, 
                                min: { value: 1, message: t("Quantity must be at least 1") }, 
                                max: { value: 6000, message: t("Quantity must not exceed 6000") },
                                validate: {
                                    isInteger: value => Number.isInteger(value) || t("Quantity must be an integer"),
                                }
                                })}
                                id="quantity" 
                                placeholder="1" 
                                className="h-[60px] mt-1 block w-full px-3 py-2"
                                name="quantity"
                                disabled={isQuantityDisabled}
                                onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value) || 0); }}
                                />
                            {errors.quantity && <p className="text-red-500 absolute text-sm">{t(errors.quantity.message)}</p>}
                        </div>
                    
                    </div>

                    <div class="grid md:grid-cols-2 sm:grid-cols-1 gap-y-8 mt-5">
                        <div className='ml-2 w-100 w-[100px]'>
                            <TranslatedIsSoldSwitch 
                            options={['nonSale', 'Sale']} 
                            value={isArtworkForSale} 
                            onChange={(value)=>setIsArtworkForSale(value)} />
                        </div>
                        <div class="hidden sm:block"></div>
                            <ProfileSearchField 
                                defaultProfile={formData?.metadata?.owner}
                                onChange={(e, value) => setOwner(value)}
                                onReset={onReset}
                                disbaled={isSelectProfileDisabled}
                            />
                        </div>
                
                </form>
                </Dialog.Description>
            
                <div className="flex flex-row pt-10 mb-2">
                    <div className='w-1/2'>
                        <div className='custom-field'>
                            <CustomButton 
                            className='primaryButton'
                            style={{
                                color:'white',
                                backgroundColor: 'rgb(56 56 56 / 60%)'
                            }}
                            onClick={closeModal}
                            singleUse={true}>
                                <span>{t('Close')}</span>
                            </CustomButton>
                        </div>
                    </div>

                    <div className='w-1/2'>
                        <div className='custom-field'>
                            <button 
                            onClick={onSubmit}
                            disabled={!isValid}
                            className='primaryButton cursor-pointer'>
                                <span>{t('Save')}</span>
                            </button>
                        </div>
                    </div>
                </div>

            </Dialog.Panel>
        </div>
        </Dialog>
    </>
  );
}
