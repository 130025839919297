import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from '../services/supabaseClient';
import userService from '../services/user.service';
import _, { OTPComponent } from '../components/login.component';
import BecomeArtistForm from '../components/modules/artwork.signup';
import UserService from '../services/user.service'
import { useTranslation } from 'react-i18next'


const RegisterArtistPage = () => {
  const [user, setUser] = useState(null);
  const [requestId, setRequestId] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [previewUrl, setPreviewUrl] = useState('');
  const { t } = useTranslation()


  useEffect(() => {
    const initializeUser = async () => {
      try {
        const session = await supabase.auth.getSession();
        if (session?.data?.session?.user?.user_metadata) {
          const userMetadata = session.data.session.user.user_metadata;
          setUser(userMetadata);

          if (!userMetadata.profile_id) {
            navigate('/login');
          } else {
            await fetchRequestId();
          }
        }
      } catch (error) {
        console.error('Error initializing user:', error);
      }
    };

    initializeUser();
  }, [navigate]);


  useEffect(() => {
  }, [previewUrl]);

  const fetchRequestId = async () => {
    try {
      const response = await userService.statusArtist();
      setRequestId(response.data.id);
    } catch (error) {
      console.error('Error fetching request ID:', error);
    }
  };

  const handleCodeSubmit = async (code) => {
    if(code != 0){
      try {
        const response = await userService.redeemReferral({ code, request_id: requestId });
        if (response.status === 200) {
          window.location.reload();
        } else {
          setMessage(response.data.message);
        }
      } catch (error) {
        setMessage(error.response?.data?.message || 'Failed to redeem referral');
      }
    }
  };

  const handleCodeChange = async () =>{
    setMessage('');
  }

  return (
  <div className='login-component' style={{marginTop: '7vh'}}>
    <div className="flex flex-col items-center justify-center min-h-screen px-4">      

      <div className='introduction-container'>
        <div className="text-center z-10 mx-auto " style={{width: '90vw'}}>

            <h1 className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl mb-1">
              {t('Welcome,')} {user?.profile_handle}
            </h1>
            
            <p className="text-lg sm:text-xl md:text-2xl mb-2">
              {t('To become an artist, submit your artwork or enter a referral code')}
            </p>

            {previewUrl && (
              <>
              <div className="image-preview mx-auto mb-2">
              <img 
              id="PreviewImage" 
              src={previewUrl} 
              alt="Preview" 
              style={{maxWidth: '100%', maxHeight: '40vh', borderRadius: '8px'}} 
              />
              </div>
              <div style={{color: '#A4A0A0'}}>
                <h6 style={{fontFamily: 'Atlas Grotesk', fontWeight: 'bolder', color: '#999999'}}>
                {t('Referral Code')}
                </h6>
              </div>
              </>
            )}

            <div className='opt-center mb-2'>
              <OTPComponent onReady={handleCodeSubmit} onChange={handleCodeChange}/>
              {message && <div className='error'>{t(message)}</div>}
            </div>
            <div className='mx-auto' style={{width: '220px'}}>
              <BecomeArtistForm onPreviewUrl={(url)=> {setPreviewUrl(url)}} />        
            </div> 
        </div>
      </div>
    </div>
  </div>
  );
};



function ArtistPage({ onStart, user }) {
  const [artworks, setArtworks] = useState([])
  const { t } = useTranslation()

  const onClickContentManagement = () => {
    window.location.href = '/dashboard'
  }

  useEffect(()=>{
    const getArtworks = async () => {
      const response = await UserService.getArtworksPaginated()
      if(response?.data?.results){
        setArtworks(response.data.results.slice(0, 3));
      }
    }
    getArtworks()
  }, [])

  
  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-screen">
          <h1 className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl mb-2" style={{color: '#f2c14d'}}>
          {t('Welcome,')}<span style={{ textTransform: 'lowercase' }}>{user?.profile_handle}</span>
          </h1>
          <div className="w-full max-w-md mx-auto">
            <p className="text-lg sm:text-xl md:text-2xl mb-2">
            {t('Ready to upload another piece of art?')}
            </p> 
          </div>
        
        <button className="primaryButton w-50 py-2 rounded-md shadow-sm"
        style={{width: '80%'}}
        onClick={onStart}>
          <span>
          {t('Upload')}
          </span>
        </button>

        <button 
        className="primaryButton slideButton w-50 py-2 rounded-md shadow-sm mt-2" 
        style={{width: '80%', background: '#000000', border: '1px #F2C14D solid', color: '#F2C14D', boxShadow:'0px 0px 15px 5px #F2C14D'}}
        onClick={onClickContentManagement}
        >
          <span>
          {t('Manage my content')}
          </span>
        </button>
        <div className='imageScroll'>
          {artworks && artworks.map((artwork, index) => {
            return <img src={artwork.artwork_images?.main_600} alt={`Artwork ${index + 1}`} className={`img${index + 1}`} key={index} />
          })}
        </div>

        <div className='mt-5'>
          <a href='https://onelink.to/txubcf' target='_blank' className="small-introduction text-lg sm:text-xl md:text-2xl">
          {t('Download now')}
          </a>
        </div>
      </div>

      {/* <div className="fixed inset-x-0 bottom-0 pb-4 bg-transparent"> 
        <div className="flex flex-col sm:flex-row justify-center items-center space-x-0 sm:space-x-2">
          <div className='mt-5'>
            <a href='https://onelink.to/txubcf' target='_blank' className="small-introduction text-lg sm:text-xl md:text-2xl">Download now</a>
          </div>
        </div>
      </div> */}
      
    </>
  );
}


function Introduction({ onStart }) {
  const [user, setUser] = useState(null);
  const [isArtist, setIsArtist] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true); 
  const navigate = useNavigate();
  const { t } = useTranslation()

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await fetchUserAndProfile();
      await fetchUserArtistStatus();
      setIsLoading(false); 
    };

    fetchData();
  }, [navigate]);

  const fetchUserAndProfile = async () => {
    try {
      const session = await supabase.auth.getSession();
      if (session?.data?.session?.user?.user_metadata) {
        const userMetadata = session.data.session.user.user_metadata;
        setUser(userMetadata);

        if (!userMetadata.profile_id) {
          navigate('/login');
        } else {
          const profile = await userService.getProfile(userMetadata.profile_id);
          setIsArtist(profile?.data?.profile_type === 'artist' || profile?.data?.profile_type === 'institution');
        }
      }
    } catch (error) {
      console.error('Error fetching user and profile:', error);
    }
  };

  const fetchUserArtistStatus = async () => {
    try {
      const hasApplied = await userService.statusArtist();
      setIsSubmittingForm(true);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setIsSubmittingForm(false); 
        console.error('User artist status not found.');
      } else {
        setIsSubmittingForm(false);
        console.error('Error fetching user artist status:', error);
      }
    }
  };

  if (isLoading) {
    return <div></div>; 
  }

  const handleBecomeArtist = () => {
    setIsSubmittingForm(true);
  };

  return (
    <>
      {isArtist ? (
        <div className="introduction-container flex flex-col justify-center items-center min-h-screen px-4">
          <div className="text-center z-10 w-full max-w-md mx-auto">
            <div className="flex flex-col items-center">
              <ArtistPage onStart={onStart} user={user}/>
            </div>
          </div>
        </div>
          ) : (
            <RegisterArtistPage/>
          )
      }
    </>
  );
}

export default Introduction;


