import React, { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import userService from '../../services/user.service';
import CustomButton from '../customComponents/buttons';
import _, { convertAndResizeImage, convertAndResizeImageWithExtension } from '../../utils/manageUploads'
import { useTranslation } from 'react-i18next'

const ProcessRequest = async (imageFile) => {
  const formData = new FormData();
  const compressedFile = await convertAndResizeImageWithExtension(imageFile);
  formData.append("file", compressedFile);
  formData.append("submission_reason", "React App Request");
  return userService.requestArtist(formData);
};

const GetStatus = async () => {
  return userService.statusArtist();
};

const CancelStatus = async () => {
  return userService.cancelArtist();
};

const ApplicationForm = ({ onPreviewUrl = ()=>{} }) => {
  const [file, setFile] = useState(null);
  const [fileValid, setFileValid] = useState(true);
  const [hasApplied, setHasApplied] = useState(false);
  const [isStatusFetched, setIsStatusFetched] = useState(false); // New state to track status fetch
  const [errorMessage, setErrorMessage] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const fileInputRef = useRef(null); 
  const { t } = useTranslation()

  
  useEffect(() => {
    const checkStatus = async () => {
      try {
        const response = await GetStatus();
        setHasApplied(true);
        setPreviewUrl(response.data.submission_file.source);
        onPreviewUrl(response.data.submission_file.source)
      } catch (error) {
        setHasApplied(false);
        console.error('Failed to fetch status:', error);
      } finally {
        setIsStatusFetched(true); // Set status as fetched irrespective of success or failure
      }
    };

    checkStatus();
  }, []); 

  useEffect(() => {
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
      onPreviewUrl(fileUrl)
      // Clean up
      return () => URL.revokeObjectURL(fileUrl);
    }
  }, [file]);

  const handleFileChange = (e) => {
    const newFile = e.target.files[0];
    if (newFile && newFile.type.startsWith('image/')) {
      setFile(newFile);
      setFileValid(true);
      setErrorMessage('');
    } else {
      setFileValid(false);
      setErrorMessage('Please select a valid image file.');
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setFileValid(true);
    document.getElementById('file-upload').value = '';
    setErrorMessage('');
  };

  const extractFirstErrorMessage = (error) => {
    const responseErrors = error?.response?.data;
    if (responseErrors && typeof responseErrors === 'object') {
      return responseErrors['message'];
    }
    return error.message || 'An unexpected error occurred.';
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    if (fileValid && file) {
      try {
        await ProcessRequest(file);
        setHasApplied(true);
      } catch (error) {
        const message = extractFirstErrorMessage(error);
        setErrorMessage(message);
      }
    } else {
      setErrorMessage('Please select a valid image file.');
    }
  };

  const cancelSubmit = async () => {
    await CancelStatus();
    setFile(null);
    setFileValid(true);
    setPreviewUrl('');
    onPreviewUrl('')
    setHasApplied(false);
    document.getElementById('PreviewImage').value = ''; // Use ID for consistency
  };

  if (!isStatusFetched) {
    return <div>Loading...</div>; // Render loading state or nothing until status is fetched
  }

  const handleImagePreviewClick = () => {
    fileInputRef.current.click();
  };

  // Render form based on hasApplied
  return (
    <div className="">
      <div className={`${!file? 'mt-4' : null}`}>
        {!hasApplied ? (
          <>
            <label htmlFor="file-upload" className='upload-button cursor-pointer' style={{display: file? 'none' : 'content'}}>
              {file ? '' : t('Select a file')}
              <input 
                id="file-upload" 
                ref={fileInputRef}
                type="file" 
                accept="image/*" 
                onChange={handleFileChange} 
                style={{display: 'none'}} 
              />
            </label>
            <div>
              <CustomButton className={`primaryButton ${!file? 'mt-5' : null} w-50 py-2 rounded-md shadow-sm`}
              style={{padding: ''}}
              disabled={!file} onClick={handleSubmit}>
              {!file ? (
                <span style={{fontWeight: 600, color: 'rgb(91 89 89)'}}>
                {t('Send Application')}
                </span>
              ) : (
                <span style={{fontWeight: 600, color: 'black'}}>
                {t('Send Application')}
                </span>
              )}
              </CustomButton>
                {file ? (
                <button 
                style={{padding: '20px'}} 
                onClick={handleImagePreviewClick}
                >
                  <span style={{cursor: 'pointer', color: '#A4A0A0', fontWeight: '700'}}>
                  {t('Change image')}
                  </span>
                </button>
                ) : null}
              {errorMessage && <div className="mt-2 text-sm text-red-600">{errorMessage}</div>}
            </div>
          </>
        ) : (
          <>
            <button type="button" className="primaryButton w-50 py-2 rounded-md shadow-sm" disabled={true}>
              <span>
              {t('Application in progress')}</span>
            </button>
            <button style={{padding: '20px'}} onClick={cancelSubmit}>
              <span style={{cursor: 'pointer', color: '#A4A0A0', fontWeight: '700'}}>
              {t('Cancel Application')}
              </span>
            </button>
          </>
        )}
      </div>
  </div>
  );
};

export default ApplicationForm;





// const ApplicationForm = () => {
//   const [file, setFile] = useState(null);
//   const [fileValid, setFileValid] = useState(true);
//   const [hasApplied, setHasApplied] = useState(false);
//   const [isStatusFetched, setIsStatusFetched] = useState(false); // New state to track status fetch
//   const [errorMessage, setErrorMessage] = useState('');
//   const [previewUrl, setPreviewUrl] = useState('');

//   useEffect(() => {
//     const checkStatus = async () => {
//       try {
//         const response = await GetStatus();
//         setHasApplied(true);
//         setPreviewUrl(response.data.submission_file.source);
//       } catch (error) {
//         setHasApplied(false);
//         console.error('Failed to fetch status:', error);
//       } finally {
//         setIsStatusFetched(true); // Set status as fetched irrespective of success or failure
//       }
//     };

//     checkStatus();
//   }, []); // Removed dependency on hasApplied to prevent re-fetching on state change

//   useEffect(() => {
//     if (file) {
//       const fileUrl = URL.createObjectURL(file);
//       setPreviewUrl(fileUrl);
//       // Clean up
//       return () => URL.revokeObjectURL(fileUrl);
//     }
//   }, [file]);

//   const handleFileChange = (e) => {
//     const newFile = e.target.files[0];
//     if (newFile && newFile.type.startsWith('image/')) {
//       setFile(newFile);
//       setFileValid(true);
//       setErrorMessage('');
//     } else {
//       setFileValid(false);
//       setErrorMessage('Please select a valid image file.');
//     }
//   };

//   const handleRemoveFile = () => {
//     setFile(null);
//     setFileValid(true);
//     document.getElementById('file-upload').value = '';
//     setErrorMessage('');
//   };

//   const extractFirstErrorMessage = (error) => {
//     const responseErrors = error?.response?.data;
//     if (responseErrors && typeof responseErrors === 'object') {
//       return responseErrors['message'];
//     }
//     return error.message || 'An unexpected error occurred.';
//   };

//   const handleSubmit = async (e) => {
//     // e.preventDefault();
//     if (fileValid && file) {
//       try {
//         await ProcessRequest(file);
//         setHasApplied(true);
//       } catch (error) {
//         const message = extractFirstErrorMessage(error);
//         setErrorMessage(message);
//       }
//     } else {
//       setErrorMessage('Please select a valid image file.');
//     }
//   };

//   const cancelSubmit = async () => {
//     await CancelStatus();
//     setFile(null);
//     setFileValid(true);
//     setPreviewUrl('');
//     setHasApplied(false);
//     document.getElementById('PreviewImage').value = ''; // Use ID for consistency
//   };

//   if (!isStatusFetched) {
//     return <div>Loading...</div>; // Render loading state or nothing until status is fetched
//   }

//   // Render form based on hasApplied
//   return (
//     <div className="application-form-container">
//     <form className="space-y-4">
//       <div className='register-upload-options'>
//         {previewUrl && (
//           <div className="image-preview">
//             <img id="PreviewImage" src={previewUrl} alt="Preview" className="mt-2" style={{maxWidth: '100%', maxHeight: '40vh'}} />
//           </div>
//         )}
//         {!hasApplied ? (
//           <>
//             <label htmlFor="file-upload" className='upload-button cursor-pointer'>
//               {file ? 'Change file' : 'Choose File'}
//               <input 
//                 id="file-upload" 
//                 type="file" 
//                 accept="image/*" 
//                 onChange={handleFileChange} 
//                 style={{display: 'none'}} 
//               />
//             </label>
//             <div>
//               {/* <button type="submit" className="primaryButton mt-10 w-50 py-2 rounded-md shadow-sm" disabled={!file}>
//                 <span>Submit Application</span>
//               </button> */}
//               {/* <CustomButton className="primaryButton mt-10 w-50 py-2 rounded-md shadow-sm" disabled={!file} onClick={handleSubmit}>
//                 <span style={{fontWeight: 600, color: 'black'}}>Submit Application</span>
//               </CustomButton> */}
//               {errorMessage && <div className="mt-2 text-sm text-red-600">{errorMessage}</div>}
//             </div>
//           </>
//         ) : (
//           <>
//             <button type="button" className="primaryButton w-50 py-2 rounded-md shadow-sm" disabled={true}>
//               <span>Application in progress</span>
//             </button>
//             <span style={{cursor: 'pointer'}} onClick={cancelSubmit}>Cancel Application</span>
//           </>
//         )}
//       </div>
//     </form>
//   </div>
//   );
// };